import React, { Component } from "react";
import "./hp.css";
import GenerateQR from "../QR/qr";
import { saveAs } from "file-saver";

class Homepage extends Component {
  constructor() {
    super();
    this.state = {
      val: "",
      url: "",
      str:""
    };
  }

  componentDidMount() {
    document
      .querySelector("div.hpQRcode svg")
      .children[1].classList.add("qrPath");
  }

  componentDidUpdate() {
    //this.state.val.length > 0 && this.svgToPng();
  }

  handleChange = e => {
    if(e.target.value===""){
      this.setState({val:""})
    }
    this.setState({str:e.target.value});
  };

  generate=()=>{
    const {str}=this.state;
    var urlR = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
    if(urlR.test(str)){
      this.setState({val:str},()=>{
        if(this.props.logIn){
          this.saveHistory();
        }
      });
    }else{
      this.props.setMsg("Please enter a valid link");
    }
  }
  clear=()=>{
    this.setState({val:"",str:""})
  }

  svgToPng = () => {
    let svg = document.querySelector("svg");
    let svgData = new XMLSerializer().serializeToString(svg);

    let canvas = document.createElement("canvas");
    let svgSize = svg.getBoundingClientRect();
    canvas.width = svgSize.width;
    canvas.height = svgSize.height;
    let ctx = canvas.getContext("2d");

    let img = document.createElement("img");
    img.setAttribute("src", "data:image/svg+xml;base64," + btoa(svgData));

    img.onload = function() {
      ctx.drawImage(img, 0, 0, svgSize.width, svgSize.height);

      // Now is done
      console.log(canvas.toDataURL("image/png"));
      let content = canvas.toDataURL("image/png");

      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(content.split(",")[1]);

      // separate out the mime component
      var mimeString = content
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);

      // create a view into the buffer
      var ia = new Uint8Array(ab);

      // set the bytes of the buffer to the correct values
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      // write the ArrayBuffer to a blob, and you're done
      var file = new Blob([ab], { type: mimeString });

      saveAs(new Blob([file], { type: "image/png" }), "QR-Eye.png");
    };
  };
  saveHistory=async ()=>{
    const {axios}=this.props;
    const {val}=this.state;
    try{
      const resp=await axios.post("/saveHistory",{val});
      this.props.setMsg(resp.data.msg)
    }catch(err){
      this.props.setMsg(err.message)
    }
  }
  render() {
    return (
      <>
        <div className="hpContainer">
          <div className="hpLinks">
            {this.props.pro ? <p>&nbsp;</p> : <p>Upgrade</p>}
            <p onClick={() => this.props.pageChange(2)}>History</p>
          </div>

          <div className={this.props.mobile ? "hpContent mob" : "hpContent"}>
            {/**LABEL */}
            <div className="hpLabel">
              {this.state.val.length > 0 ? (
                <p>
                  Scan or <span onClick={this.svgToPng}>Download</span>
                </p>
              ) : (
                <p>
                  <span
                    onClick={() =>
                      saveAs(
                        "https://qreye.xynes.com/QR%20eye.apk",
                        "application/apk"
                      )
                    }
                  >
                    Download
                  </span>{" "}
                  Scanner
                </p>
              )}
            </div>
            {/**LABEL */}

            {/**QR CODE */}
            {/**QR CODE */}
            <div className={this.props.mobile ? "hpQRcode mob" : "hpQRcode"}>
              {this.state.val.length > 0 ? (
                <>
                  <GenerateQR val={this.state.val} />
                </>
              ) : (
                <>
                  <GenerateQR val="https://xynes.com/" />
                </>
              )}
            </div>
            {/**QR CODE */}
            {/**QR CODE */}

            {/**INPUT BAR */}
            {/**INPUT BAR */}
            <div className={this.props.mobile ? "hpInput mob" : "hpInput"}>
              <input
                type="text"
                placeholder="Paste your link here."
                autoComplete="off"
                spellCheck="false"
                id="text"
                onChange={this.handleChange}
                value={this.state.str}
              />
              {}
              <p onClick={()=>{
                this.state.val.length > 0 ? this.clear():this.generate();
              }}>
                {this.state.val.length > 0 ? "Clear" : "Generate"}
              </p>
            </div>
            {/**INPUT BAR */}
            {/**INPUT BAR */}
          </div>
        </div>
      </>
    );
  }
}

export default Homepage;
