import React, { Component } from "react";
import "./index.css";
import Homepage from "../homePage/hp";
import History from "../history/history";

class Index extends Component {
  constructor() {
    super();
    this.state = {
      page: 1
    };
  }

  componentDidUpdate() {
    this.state.page === 2 &&
      document.getElementById("swipe").classList.add("swipe");
    this.state.page === 1 &&
      document.getElementById("swipe").classList.remove("swipe");
  }

  pageChange = no => {
    this.setState({ page: no });
  };

  render() {
    return (
      <>
        <div className={this.props.chrome ? "chrome" : "container"}>
          <div
            className={this.props.chrome ? "swiper chrm" : "swiper"}
            id="swipe"
          >
            {/**PAGE ONE */}
            <div className={this.props.chrome ? "siperPage chrm" : "siperPage"}>
              <Homepage
                logIn={this.props.logIn}
                pro={this.props.pro}
                mobile={this.props.mobile}
                chrome={this.props.chrome}
                pageChange={this.pageChange}
                setMsg={this.props.setMsg}
                axios={this.props.axios}
              />
            </div>
            {/**PAGE ONE */}

            {/**PAGE Two */}
            {this.state.page===2 &&  <div className={this.props.chrome ? "siperPage chrm" : "siperPage"}>
              
              <History
                logIn={this.props.logIn}
                pro={this.props.pro}
                isLogin={this.props.isLogin}
                isPro={this.props.isPro}
                mobile={this.props.mobile}
                chrome={this.props.chrome}
                pageChange={this.pageChange}
                setMsg={this.props.setMsg}
                axios={this.props.axios}
              />
            </div>}
           
            {/**PAGE Two */}
          </div>
        </div>
      </>
    );
  }
}

export default Index;
