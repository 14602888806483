import React, { Component } from "react";
import "./msg.css";

class Msg extends Component {
  render() {
    return (
      <>
        <div
          className="msgBoxContainer"
          id="msgBox"
          onClick={this.props.onClick}
        >
          <div className="msgBox">
            <p>{this.props.msg}</p>
          </div>
        </div>
      </>
    );
  }
}

export default Msg;
