import React, { Component } from "react";
import "./logIn.css";

class LogIn extends Component {
  constructor() {
    super();

    this.state = {
      username: "",
      password: "",
      emailErr: false,
      passErr: false,
      errMsg1: "",
      errMsg2: "",
      hidePassword: true,
      btnLoading: false
    };
  }

  handleChange = event => {
    this.setState({ username: event.target.value, emailErr: false });
  };

  managePasswordVisibility = () => {
    this.setState({ hidePassword: !this.state.hidePassword });
  };
  LogIn=()=>{
    const {username,password}=this.state;
    if(!username){
      this.setState({emailErr:true,errMsg1:"You can't leave this field empty"})
    }
    if(!password){
        this.setState({passErr:true,errMsg2:"Please Enter a Password"})
    }
    if(username && password){
      //axios call
      if(!this.state.btnLoading){
        this.setState({btnLoading:true});
        this.props.axios.post("/login",{username,password}).then(resp=>{
          this.setState({btnLoading:false});
          if(resp.data.status){
            //login
            localStorage.setItem('token',resp.data.token);
            this.props.isLogin(true);
            // this.props.setUserInfo({
            //   userName:resp.data.username,
            //   email:resp.data.email,
            //   abatarIMG:resp.data.dp
            // })
            this.props.setMsg("Authentication successfull")
          }else{
            //error
            this.props.setMsg(resp.data.msg);
          }
        }).catch(err=>{
          this.setState({btnLoading:false});
          this.props.setMsg(err.message);
        })
      }
      
    }
  }
  render() {
    return (
      <>
        {/**logIn Section */}
        {/**logIn Section */}
        <div className="logInSec">
          <div className="lpuserForm">
            <p className="lpFormLabel">Log in to continue</p>

            <div className="lpUseranemSec">
              <input
                type="text"
                placeholder="Enter your email"
                onChange={this.handleChange}
                value={this.state.username}
              />
              <span className={!this.state.emailErr ? "hide" : null}>
                {this.state.errMsg1}
              </span>
            </div>

            <div className="lpPasswordSec">
              <input
                type={this.state.hidePassword ? "password" : "text"}
                placeholder="Enter password"
                onChange={e => {
                  this.setState({ password: e.target.value, passErr: false });
                }}
                value={this.state.password}
              />
              <div
                className="lpVisibleBtn"
                onClick={this.managePasswordVisibility}
              >
                <img
                  src={
                    this.state.hidePassword
                      ? require("../../images/show.svg")
                      : require("../../images/hide.svg")
                  }
                  className="lpVisibleIcn"
                />
              </div>
              <span className={!this.state.passErr ? "hide" : null}>
                {this.state.errMsg2}
              </span>
            </div>

            <div className="lpLogInBtn" onClick={this.LogIn}>
              {this.state.btnLoading ? <p>Loading...</p> : <p>Log In</p>}
            </div>
          </div>

          <div
            className="lplogInButtons"
            onClick={() => window.open("https://xynes.com/sign-up")}
          >
            <div className="lpcaIcon xy" />
            <p>Create new account</p>
          </div>

          <div className="lplogInButtons">
            <div className="lpcaIcon google" />
            <p>Log in with Google</p>
          </div>

          <div className="lplogInButtons fbBtn">
            <div className="lpcaIcon fb" />
            <p>Log in with Facebook</p>
          </div>
        </div>

        {/**logIn Section */}
        {/**logIn Section */}
      </>
    );
  }
}

export default LogIn;
