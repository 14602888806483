import React, { Component } from "react";
import GenerateQR from "../QR/qr";
import Timestamp from "react-timestamp";
import { saveAs } from "file-saver";
import "./hsitem.css";

const svgToPng = () => {
  let svg = document.querySelector("svg");
  let svgData = new XMLSerializer().serializeToString(svg);

  let canvas = document.createElement("canvas");
  let svgSize = svg.getBoundingClientRect();
  canvas.width = svgSize.width;
  canvas.height = svgSize.height;
  let ctx = canvas.getContext("2d");

  let img = document.createElement("img");
  img.setAttribute("src", "data:image/svg+xml;base64," + btoa(svgData));

  img.onload = function() {
    ctx.drawImage(img, 0, 0, svgSize.width, svgSize.height);

    // Now is done
    console.log(canvas.toDataURL("image/png"));
    let content = canvas.toDataURL("image/png");

    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(content.split(",")[1]);

    // separate out the mime component
    var mimeString = content
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var file = new Blob([ab], { type: mimeString });

    saveAs(new Blob([file], { type: "image/png" }), "QR-Eye.png");
  };
};

const copy = (val, props) => {
  let text = val;

  try {
    navigator.clipboard.writeText(text).then(
      function() {
        props("Link Coppied.");
      },
      function(err) {
        props("Async: Could not copy text: ", err);
      }
    );
  } catch (e) {
    props("Your browser doesn't support clipboard");
  }
};

const HsItems = props => {
  return (
    <div className="hsItem">
      <div className="hsLeft">
        {/**QR CODE */}
        <div className="hsQrContainer">
          <GenerateQR val={props.link} />
        </div>
        {/**QR CODE */}
      </div>
      <div className="hsRight">
        {/**TOP */}
        <div className="hsRightLebel">
          <button onClick={() => copy(props.link, props.setMsg)}>
            {props.link}
          </button>
          <p>
            <Timestamp date={props.time} />
          </p>
        </div>
        {/**TOP */}

        <p onClick={svgToPng}>Download</p>
      </div>
    </div>
  );
};

export default HsItems;
