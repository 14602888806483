import React, { Component } from "react";
let QRCode = require("qrcode.react");

const GenerateQR = props => {
  console.log(props)
  return (
    <QRCode
      value={props.val}
      level="H"
      fgColor="#191654"
      bgColor="#f5f5f5"
      size={256}
      renderAs="svg"
      includeMargin={true}
    />
  );
};

export default GenerateQR;
