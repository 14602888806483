import React, { Component } from "react";
import "./loading.css";

const Load = () => {
  return (
    <div className="loadingDiv">
      <div className="loadingLeft">
        <div className="loadCircle" />
      </div>

      <div className="loadingRight">
        <div className="lrTop">
          <div />
          <div />
        </div>

        <div className="leBottom" />
      </div>
    </div>
  );
};

export default Load;
