import React, { Component } from "react";
import LogIn from "../logIn/logIn";
import HsItems from "../historyItems/hsItem";
import Load from "../loading/loading";
import { Scrollbars } from "react-custom-scrollbars";
import ScrollToFetch from 'react-scroll-to-fetch';
import "./history.css";

class History extends Component {
  constructor() {
    super();
    this.state = {
      hasMore:true,
      history: [
        // { id: 1, link: "https://github.com/", time: "1558753290" },
        // { id: 2, link: "https://github.com/", time: "1558753290" },
        // { id: 3, link: "https://github.com/", time: "1558753290" },
        // { id: 4, link: "https://github.com/", time: "1558753290" },
        // { id: 5, link: "https://github.com/", time: "1558753290" },
        // { id: 6, link: "https://github.com/", time: "1558753290" },
        // { id: 7, link: "https://github.com/", time: "1558753290" },
        // { id: 8, link: "https://github.com/", time: "1558753290" },
        // { id: 9, link: "https://github.com/", time: "1558753290" },
        // { id: 10, link: "https://github.com/", time: "1558753290" },
        // { id: 11, link: "https://github.com/", time: "1558753290" },
        // { id: 12, link: "https://github.com/", time: "1558753290" },
        // { id: 13, link: "https://github.com/", time: "1558753290" },
        // { id: 14, link: "https://github.com/", time: "1558753290" },
        // { id: 15, link: "https://github.com/", time: "1558753290" },
        // { id: 16, link: "https://github.com/", time: "1558753290" }
      ]
    };
  }
  loadHistory=async page=>{
    try{
      const {axios}=this.props;
      const resp=await axios.post("/loadHistory",{page});
      if(resp.data.status){
        this.setState(({history})=>{
          return {history:[...history,...resp.data.list],hasMore:resp.data.hasMore}
        });
      }else{
        this.setState({hasMore:false});
      }
    }catch(err){
      this.props.setMsg(err.message);
    }
  }

  render() {
    return (
      <>
        <div className="historySec">
          <div
            className={
              this.props.mobile ? "historyContainer mob" : "historyContainer"
            }
          >
            <div className="historyLabel">
              <p onClick={() => this.props.pageChange(1)}>Back</p>
            </div>

            {!this.props.logIn && (
              <div className={this.props.mobile ? "hsLogIn mob" : "hsLogIn"}>
                <LogIn axios={this.props.axios} setMsg={this.props.setMsg} isLogin={this.props.isLogin}
                isPro={this.props.isPro}/>
              </div>
            )}

            {this.props.logIn && (
              <div className="histories">
                <div className="historiesScroll">
                  <Scrollbars
                    ref="scrollbars"
                    style={{ width: "100%", height: "100%" }}
                    renderTrackVertical={props => (
                      <div {...props} className="track-vertical" />
                    )}
                    renderThumbVertical={props => (
                      <div {...props} className="thumb-vertical" />
                    )}
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                    universal={true}
                    id="history_scroll"
                  > 
                    <ScrollToFetch
                          loader={
                            <div className={
                              this.props.mobile
                                ? "hsItemContain mob"
                                : "hsItemContain"
                            }>
                              <Load/>
                            </div>
                          }
                          fetch={this.loadHistory}
                          finished={!this.state.hasMore}
                          className="historiesContain"
                          scrollParent="history_scroll"
                          initialLoad={true}
                          debug
                          successMessage={
                            this.state.history.length === 0 ? (
                              <p className="no_result">No results found</p>
                            ) : (
                              <p> </p>
                            )
                          }
                        >
                      {this.state.history.map(history => (
                        <div
                          className={
                            this.props.mobile
                              ? "hsItemContain mob"
                              : "hsItemContain"
                          }
                          key={history.id}
                        >
                          <HsItems
                            link={history.val}
                            time={history.createdOn}
                            setMsg={this.props.setMsg}
                          />
                        </div>
                      ))}
                    </ScrollToFetch>
                  </Scrollbars>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default History;
