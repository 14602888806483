import React, { Component } from "react";
import "./App.css";
import { api } from "./conf";
import axios from "axios";
import Index from "./components/index";
import {
  BrowserView,
  MobileView,
  CustomView,
  browserName
} from "react-device-detect";

import Msg from "./components/msg/msg";
const instance = axios.create({
  baseURL: api,
  timeout: 5000,
  headers: {
    Authorization:
      localStorage.getItem("token") !== null
        ? "Bearer " + localStorage.getItem("token")
        : null
  }
});
class App extends Component {
  constructor() {
    super();
    this.state = {
      logIn: localStorage.getItem("token") === null ? false : true,
      pro: false,
      msg: "Welcome",
      link: ""
    };
  }
  isLogin = value => {
    if (value === true) {
      instance.defaults.headers["Authorization"] =
        "Bearer " + localStorage.getItem("token");
    } else {
      instance.defaults.headers["Authorization"] = null;
    }
    this.setState({ logIn: value });
  };
  isPro = value => {
    this.setState({ pro: value });
  };

  componentDidMount() {
    instance.interceptors.response.use(undefined, error => {
      if (error.response && error.response.status === 401) {
        this.isLogin(false);
        localStorage.removeItem("token");
        error.message = "Security Token Expired, Please login Again";
        return Promise.reject(error);
      } else if (error.response && error.response.status === 403) {
        localStorage.removeItem("token");
        this.isLogin(false);
        error.message = "Failed to Retrive account details";
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    });
    this.state.msg.length > 0 &&
      setTimeout(() => this.setState({ msg: "" }), 3000);
  }

  componentDidUpdate() {
    this.state.msg.length > 0 &&
      setTimeout(() => this.setState({ msg: "" }), 3000);
  }

  setMsg = msg => {
    this.setState({
      msg: msg
    });
  };

  render() {
    return (
      <>
        {this.state.msg.length > 0 && (
          <Msg
            onClick={() => this.setState({ msg: "" })}
            msg={this.state.msg}
          />
        )}
        <BrowserView>
          <Index
            logIn={this.state.logIn}
            pro={this.state.pro}
            setMsg={this.setMsg}
            axios={instance}
            isLogin={this.isLogin}
            isPro={this.isPro}
          />
        </BrowserView>

        <MobileView>
          <CustomView condition={browserName !== "Chrome"}>
            <Index
              logIn={this.state.logIn}
              isLogin={this.isLogin}
              isPro={this.isPro}
              pro={this.state.pro}
              mobile={true}
              setMsg={this.setMsg}
              axios={instance}
            />
          </CustomView>

          <CustomView condition={browserName === "Chrome"}>
            <Index
              logIn={this.state.logIn}
              pro={this.state.pro}
              isLogin={this.isLogin}
              isPro={this.isPro}
              mobile={true}
              chrome={true}
              setMsg={this.setMsg}
              axios={instance}
            />
          </CustomView>
        </MobileView>
      </>
    );
  }
}

export default App;
